import useFetch from '../../../hooks/useFetch';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper";

import "swiper/css";
import "swiper/css/pagination";

export default function InfoDreamCar() {

    let url_api = 'global/dreamcar'
    const [respuesta, error] = useFetch(url_api)

    let dream_car_image = ''
    let dream_car_title = ''
    let dream_car_body = ''
    let dream_car_form = ''
    let dream_car_base = ''
    let dream_car_bullet_list = ''
    let dream_car_body_2 = ''
    let dream_car_body_3 = ''
    let dream_car_winner_1 = ''
    let dream_car_winner_2 = ''
    let dream_car_winner_3 = ''
    let dream_car_item_1 = ''
    let dream_car_item_2 = ''
    let dream_car_item_3 = ''
    let dream_car_item_4 = ''
    let dream_car_btn_1 = ''
    let dream_car_btn_2 = ''

    
    if (respuesta) {
        respuesta.map(c => {
                dream_car_title         = c.dream_car_title
                dream_car_image         = c.dream_car_image
                dream_car_body          = c.dream_car_body
                dream_car_form          = c.dream_car_form
                dream_car_base          = c.dream_car_base
                dream_car_bullet_list   = c.dream_car_bullet_list
                dream_car_body_2        = c.dream_car_body_2
                dream_car_body_3        = c.dream_car_body_3
                dream_car_winner_1      = c.dream_car_winner_1
                dream_car_winner_2      = c.dream_car_winner_2
                dream_car_winner_3      = c.dream_car_winner_3
                dream_car_item_1        = c.dream_car_item_1
                dream_car_item_2        = c.dream_car_item_2
                dream_car_item_3        = c.dream_car_item_3
                dream_car_item_4        = c.dream_car_item_4
                dream_car_btn_1         = c.dream_car_btn_1
                dream_car_btn_2         = c.dream_car_btn_2
            }
        )
    }

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12">
                        <a 
                            href={process.env.REACT_APP_URL_DOMAIN + dream_car_base} 
                            className='dream_car_form' 
                            target="_blank" title='Conoce más'>
                                <img src={process.env.REACT_APP_URL_DOMAIN + dream_car_image} className="img-fluid" />
                        </a>
                    </div>
                    <div className="col-12 col-sm-12 mt-4 mb-4">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item active">
                                    <a href={ process.env.REACT_APP_URL_DOMAIN} title="Toyota Ecuador">Inicio</a>
                                </li>
                                <li class="breadcrumb-item" aria-current="page">Dream Car Ecuador</li>
                            </ol>
                        </nav>
                    </div>

                    <div className="col-12 col-sm-12 col-12 text-center mt-4 bg-purple-full">
                        <p className='dream_car_body'>{dream_car_body_3}</p>
                    </div>

                    <div className="col-12 col-sm-12 col-12 mt-4 mb-4">
                        <Swiper
                            spaceBetween={30}
                            centeredSlides={true}
                            autoplay={{
                            delay: 4000,
                            disableOnInteraction: false,
                            }}
                            pagination={{
                            clickable: true,
                            }}
                            navigation={true}
                            modules={[Autoplay, Pagination, Navigation]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <img className="img-fluid" src={process.env.REACT_APP_URL_DOMAIN + dream_car_winner_1} alt="Dream Car - Ganadores" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img className="img-fluid" src={process.env.REACT_APP_URL_DOMAIN + dream_car_winner_2} alt="Dream Car - Ganadores" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img className="img-fluid" src={process.env.REACT_APP_URL_DOMAIN + dream_car_winner_3} alt="Dream Car - Ganadores" />
                            </SwiperSlide>
                        </Swiper>
                    </div>

                    <div className="col-12 col-sm-6 col-md-3"></div>

                    <div className="col-12 col-sm-12 col-12 text-center mb-4">
                        <p className='dream_car_body text-bold-rules'>{dream_car_body_2}</p>
                    </div>

                    <div className="col-12 col-sm-6 col-md-3 text-center dream-car-items">
                        <div class="card">
                            <h5 class="card-title">Obras de arte que utilizan fotografías, imágenes gratuitas, productos comprados</h5>
                            <img src={ process.env.REACT_APP_URL_DOMAIN + dream_car_item_1} className="mx-2" alt='Bases del concurso' />
                            <div class="card-body">
                                <p class="card-text">(Stickers, papel de regalo, periódico, etc.) Obras de arte que incluyen personajes de marcas registradas, Logotipos de corporaciones y artículos específicos.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-3 text-center dream-car-items">
                        <div class="card">
                            <h5 class="card-title">Obras de arte que se parecen a obras anteriores del Toyota Dream Car Art Contest</h5>
                            <img src={ process.env.REACT_APP_URL_DOMAIN + dream_car_item_2} className="mx-2" alt='Bases del concurso' />
                            <div class="card-body">
                                <p class="card-text">Pinturas famosas, pinturas creadas por otros artistas.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-3 text-center dream-car-items">
                        <div class="card">
                            <h5 class="card-title">Obras de arte que incluyan Vehículos de otras marcas</h5>
                            <img src={ process.env.REACT_APP_URL_DOMAIN + dream_car_item_3} className="mx-2" alt='Bases del concurso' />
                            <div class="card-body">
                                <p class="card-text">Se excluyen las marcas que no sean de TOYOTA.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-3 text-center dream-car-items">
                        <div class="card">
                            <h5 class="card-title">Obras de arte creadas por una Persona distinta al participante</h5>
                            <img src={ process.env.REACT_APP_URL_DOMAIN + dream_car_item_4} className="mx-2" alt='Bases del concurso' />
                            <div class="card-body">
                                <p class="card-text">Que hayan tenido la ayuda de adultos (padres, tutor legal, Instructor, etc.)</p>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-12 col-sm-12 hide">
                        <h1 className="dream_car_title_download">DESCARGA TU KIT DE PARTICIPACIÓN</h1>
                    </div>
                </div>
            </div>
        </>
    );
}